/* eslint-disable import/no-cycle */
import { FC } from 'react'
import classNames from 'classnames'
import { v1 } from 'uuid'

import { NewsCard } from '../../../../components/newsCard'
import { INewsCardItem, NewsSlider } from '../../../../components/newsSlider'
import { NavRef } from '../Footer/navigation'

import { ReactComponent as NewsDecor } from '../../../../assets/img/icons/svg/NewsDecor.svg'

import s from './News.module.sass'

export const NewsRef: NavRef = { current: null }

interface NewsProps {
    data: INewsCardItem[]
    withoutRef?: boolean
}

export const News: FC<NewsProps> = ({ data, withoutRef = true }) => (
    <section className={s.news} id="news" ref={withoutRef ? null : NewsRef}>
        <div className={s.decor}>
            <NewsDecor />
            <div className={s.decorI}></div>
        </div>
        <div className="container">
            <p className={classNames(s.title, 'sectionTitle')}>
                <span className="textMainGradient">Latest</span> <b> </b>
        DFC NEWS
            </p>
            <div className={s.contentWrapper}>
                <NewsSlider
                    key={v1()}
                    data={data}
                    card={NewsCard}
                    options={{
                        grabCursor: true,
                        slidesPerView: 3,
                        spaceBetween: 20,
                        breakpoints: {
                            320: { slidesPerView: 1.1 },
                            425: { slidesPerView: 1.4 },
                            577: { slidesPerView: 1.8 },
                            650: { slidesPerView: 2 },
                            769: { slidesPerView: 2.1 },
                            993: { slidesPerView: 2.5 },
                            1101: { slidesPerView: 2.8 },
                            1401: { slidesPerView: 3 }
                        }
                    }}
                    slidesPerView={3}
                />
            </div>
        </div>
    </section>
)
