import { FC } from 'react'
import { v1 } from 'uuid'

import { FaqList } from '../../configs/faqList'

import { FaqCard } from './faqCard'

import s from './faqList.module.sass'

interface FaqBlockProps {}

export const FaqBlock: FC<FaqBlockProps> = () => (
    <div className={s.list}>
        {FaqList.map(el => (
            <FaqCard key={v1()} {...el} />
        ))}
    </div>
)
