/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { routes } from './configs/routes'

import MainPage from './pages/Main/MainPage'
import { NewsPage } from './pages/News/NewsPage'

const Routing = () => (
    <Routes>
        <Route path={routes.default} element={<MainPage />} />
        <Route path={routes.news} element={<NewsPage />} />
    </Routes>
)

export default Routing
