import coinGecko from '../assets/img/trusted-partners/coinGecko.png'
import coinMarketCap from '../assets/img/trusted-partners/coinmarket.png'
import dedust from '../assets/img/trusted-partners/dedust.png'
import mexc from '../assets/img/trusted-partners/mexc.png'
import stonfi from '../assets/img/trusted-partners/stonfi.png'
import xRocket from '../assets/img/trusted-partners/xRocket.png'
import BitGet from '../assets/img/trusted-partners/bitget.png'
import { ITrustedPartnerCard } from '../components/trustedPartnerCard'

export const trustedPartnersList: ITrustedPartnerCard[] = [
    {
        logo: stonfi,
        title: 'STON.FI',
        link: 'https://app.ston.fi/swap?chartVisible=false&chartInterval=1y'
    },
    {
        logo: dedust,
        title: 'DeDust',
        link: 'https://dedust.io/swap/TON/DFC'
    },
    {
        logo: coinMarketCap,
        title: 'CoinMarketCap',
        link: 'https://coinmarketcap.com/currencies/definder-capital/'
    },
    {
        logo: mexc,
        title: 'MEXC',
        link: 'https://www.mexc.com/exchange/DFC_USDT'
    },
    {
        logo: xRocket,
        title: 'xRocket',
        link: 'https://t.me/xrocket/cex'
    },
    {
        logo: coinGecko,
        title: 'CoinGecko',
        link: 'https://www.coingecko.com/en/coins/definder-capital'
    },
    {
        logo: BitGet,
        title: 'Bitget',
        link: 'https://www.bitget.com/uk/spot/DFCUSDT'
    }
]
