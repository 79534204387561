/* eslint-disable import/order */
/* eslint-disable import/no-default-export */
/* eslint-disable max-len */
import { FC } from 'react'
import { Helmet } from 'react-helmet'

import HeadBlock from './pageBlocks/Head'
import WhitepaperBlock from './pageBlocks/Whitepaper'
import ProductsBlock from './pageBlocks/Products'
import TonPotential from './pageBlocks/TonPotential'
import TokenomicsBlock from './pageBlocks/Tokenomics'
import { TrustedPartners } from './pageBlocks/TrustedPartners'
import { News } from './pageBlocks/News'
import { Community } from './pageBlocks/Community'
import { Roadmap } from './pageBlocks/Roadmap'
import { Faq } from './pageBlocks/Faq'
import { Footer } from './pageBlocks/Footer'

import { newsList } from 'configs/newsList'

import Navbar from '../../components/navbar'

import style from './MainPage.module.sass'

const MainPage: FC = () => (
    <>
        <Helmet>
            <title>DeFinder Capital</title>
            <meta
                name="description"
                content="We invest in teams that build and support the decentralised web to contribute to a more inclusive, secure, and decentralized digital future."
            />
        </Helmet>
        <main className={style.mainPage}>
            <Navbar />
            <HeadBlock />
            <WhitepaperBlock />
            <ProductsBlock />
            <TonPotential />
            <TokenomicsBlock />
            <TrustedPartners />
            <News data={newsList} withoutRef={false} />
            <Community />
            <Roadmap />
            <Faq />
            <Footer />
        </main>
    </>
)

export default MainPage
