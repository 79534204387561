/* eslint-disable import/no-default-export */
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import Navigation from '../navigation'
import Button, { btnVariant } from '../button'

import DeFinderCapitalLogoWhite1 from '../../assets/img/logo/DeFinderCapitalLogoWhite1.png'

import style from './Navbar.module.sass'

const Navbar: React.FC = () => {
    const headerRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                headerRef.current?.classList.add(style.activeHeader)
            } else {
                headerRef.current?.classList.remove(style.activeHeader)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <aside className={style.navbar} ref={headerRef}>
            <div className="container">
                <div className={style.wrapper}>
                    <img
                        className={style.logo}
                        src={DeFinderCapitalLogoWhite1}
                        alt="DeFinder Capital"
                        onClick={() => navigate('/')}
                    />
                    <div className={style.navigationWrapper}>
                        <Navigation />
                    </div>
                    <div className={style.buttonsWrapper}>
                        <Button
                            link="https://t.me/metasalience"
                            variant={btnVariant.primary}
                        >
              Contact Us
                        </Button>
                        <Button
                            link="https://www.mexc.com/exchange/DFC_USDT"
                            variant={btnVariant.gradient}
                        >
              Buy DFC
                        </Button>
                    </div>
                </div>
            </div>
        </aside>
    )
}
export default Navbar
