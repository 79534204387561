/* eslint-disable import/no-default-export */
import classNames from 'classnames'

import { tokenomicsCardsCfg } from '../../../../configs/tokenomics'

import TokenomicsCards from '../../../../components/tokenomicsCards'
import { ReactComponent as TokenomicsDecor } from './img/bg.svg'

import style from './Tokenomics.module.sass'

import Sphere from '../../../../assets/img/sphere.png'

const TokenomicsBlock: React.FC = () => (
    <div className={style.tokenomicsBlock}>
        <div className="container">
            <div className={style.decor}>
                <div className={style.decorI} />
                <TokenomicsDecor />
                <img className={style.spline} src={Sphere} alt="sphere" />
            </div>
            <p className={classNames(style.title, 'sectionTitle')}>
                <span className="textMainGradient">1st TON</span> mini-game with DFC!
            </p>
            <p className={style.text}>
          Battles, politics, referral system, play against each other or against
          DF Capital, farming DFC token and burn it through commissions...
            </p>
            <div className={style.metaInfo}>
                <div className={style.metaInfoItem}>
                    <p className={style.metaInfoTitle}>TOTAL SUPPLY</p>
                    <p className={style.metaInfoValue}>100,000,000</p>
                </div>
                <div className={style.metaInfoItem}>
                    <p className={style.metaInfoTitle}>AMOUNT TO AIRDROP</p>
                    <p className={style.metaInfoValue}>50,000,000</p>
                </div>
                <div className={style.metaInfoItem}>
                    <p className={style.metaInfoTitle}>DROPPED FOR NOW</p>
                    <p className={style.metaInfoValue}>7,946,000</p>
                </div>
            </div>
            <TokenomicsCards config={tokenomicsCardsCfg} />
        </div>
    </div>
)

export default TokenomicsBlock
