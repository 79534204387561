import { FC } from 'react'
import { v1 } from 'uuid'

import classNames from 'classnames'

import { EffectCoverflow } from 'swiper/modules'
import { CommunitySlider } from '../../../../components/communitySlider'
import { CommunityCard } from '../../../../components/communityCard'

import { CommunityList } from '../../../../configs/communityList'

import s from './Community.module.sass'

interface CommunityProps {}

export const Community: FC<CommunityProps> = () => (
    <div className={s.community}>
        <div className="container">
            <p className={classNames(s.title, 'sectionTitle')}>
        OUR <span className="textMainGradient">Community</span>
            </p>

            <div className={s.contentWrapper}>
                <CommunitySlider
                    key={v1()}
                    data={CommunityList}
                    card={CommunityCard}
                    modules={[ EffectCoverflow ]}
                    options={{
                        effect: 'coverflow',
                        coverflowEffect: {
                            slideShadows: false,
                            rotate: 0
                        },
                        grabCursor: true,
                        centeredSlides: true,
                        breakpoints: {
                            200: { slidesPerView: 1, spaceBetween: 30 },
                            300: { slidesPerView: 1, spaceBetween: 30 },
                            450: { slidesPerView: 1, spaceBetween: 30 },
                            500: { slidesPerView: 1, spaceBetween: 40 },
                            600: { slidesPerView: 1, spaceBetween: 50 },
                            750: { slidesPerView: 1, spaceBetween: 60 },
                            830: { slidesPerView: 1.2 },
                            900: { slidesPerView: 1.3 },
                            1000: { slidesPerView: 1.4, spaceBetween: 100 },
                            1130: { slidesPerView: 1.6, spaceBetween: 100 },
                            1250: { slidesPerView: 1.6, spaceBetween: 190 },
                            1300: { slidesPerView: 1.7, spaceBetween: 190 },
                            1430: { slidesPerView: 1.85, spaceBetween: 190 },
                            1584: { slidesPerView: 2, spaceBetween: 190 }
                        }
                    }}
                    slidesPerView={2}
                />
            </div>
        </div>
    </div>
)
