import { TokenomicsCardConfig } from '../components/tokenomicsCards/card'
import { ReactComponent as Distribution } from '../assets/img/tokenomics/distribution.svg'
import { ReactComponent as Allocations } from '../assets/img/tokenomics/allocations.svg'
import { ReactComponent as Distribution2 } from '../assets/img/tokenomics/distribution2.svg'
import { ReactComponent as Allocations2 } from '../assets/img/tokenomics/allocations2.svg'

export const tokenomicsCardsCfg: TokenomicsCardConfig[] = [
    {
        title: 'DFC distribution, %',
        svgDiagramTablet: <Distribution />,
        svgDiagramPhone: <Distribution2 />
    },
    {
        title: 'Social allocations, M',
        svgDiagramTablet: <Allocations />,
        svgDiagramPhone: <Allocations2 />
    }
]
