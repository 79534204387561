/* eslint-disable import/no-cycle */
import { FC } from 'react'
import classNames from 'classnames'

import { FaqBlock } from '../../../../components/faqList'
import LinkWrapper from '../../../../components/linkWrapper'

import { NavRef } from '../Footer/navigation'

import s from './Faq.module.sass'

export const FaqRef: NavRef = { current: null }

interface FaqProps {}

export const Faq: FC<FaqProps> = () => (
    <section className={s.faq} id="FAQ" ref={FaqRef}>
        <div className={classNames(s.faqInner, 'container')}>
            <div className={s.faqInfo}>
                <div>
                    <div className={s.faqInfoTitle}>FAQ</div>
                    <p className={s.faqInfoDescription}>
              Got questions about DFC or DF Capital? Check it out!
                    </p>
                </div>
                <div className={s.faqInfoBlock}>
                    <p className={s.faqInfoBlockT}>More Questions?</p>
                    <LinkWrapper
                        className={s.faqInfoButton}
                        link="https://t.me/metasalience"
                    >
              Contact Us
                    </LinkWrapper>
                </div>
            </div>
            <FaqBlock />
        </div>
    </section>
)
