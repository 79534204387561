/* eslint-disable import/no-default-export */
import { useEffect, useState } from 'react'
import useWindowWidth from './useWindowWidth'

// Custom hook to track the width and padding of a container class relative to the window width

interface IContainerWidth {
    containerWidth: number
    paddingWidth: number
}

const useGetContainerWidth = (): IContainerWidth => {
    const [ width, setWidth ] = useState<number>(0)
    const [ padding, setPadding ] = useState<number>(0)
    const windowWidth = useWindowWidth()

    useEffect(() => {
        const container = document.querySelector('.container')
        const containerWidth = container?.clientWidth ?? 0
        const paddingWidth = (windowWidth - containerWidth) / 2
        setWidth(containerWidth)
        setPadding(paddingWidth)
    }, [ windowWidth ])

    return { containerWidth: width, paddingWidth: padding }
}

export default useGetContainerWidth
