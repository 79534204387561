/* eslint-disable no-irregular-whitespace */
import { FC } from 'react'

import className from 'classnames'

import LinkWrapper from '../../../../components/linkWrapper'

import Navigation from './navigation'

import { ReactComponent as FooterDecor } from '../../../../assets/img/logo/FooterDecor.svg'
import { ReactComponent as Telegram } from '../../../../assets/img/icons/social/telegram.svg'
import { ReactComponent as Twitter } from '../../../../assets/img/icons/social/twitter.svg'

import { ReactComponent as Capital } from '../../../../assets/img/footer/Capital.svg'

import s from './Footer.module.sass'

interface FooterProps {}

interface SocialLinkProps {
    link: string
    icon: React.ReactNode
}

const SocialLink: FC<SocialLinkProps> = ({ link, icon }) => (
    <LinkWrapper link={link} className={s.footerLink}>
        {icon}
    </LinkWrapper>
)

export const Footer: FC<FooterProps> = () => (
    <footer className={s.footer}>
        <div className={className(s.footerInner, 'container')}>
            <div className={s.footerTop}>
                <p className={s.footerTopTitle}>CAPITAL</p>
                <FooterDecor />
                <div className={s.footerTopDecor}>
                    <Capital />
                </div>
            </div>
        </div>
        <div className={className(s.footerBottom, 'container')}>
            <div className={s.footerBottomTop}>
                <div>
                    <LinkWrapper
                        link="https://t.me/metasalience"
                        className={s.contactLink}
                    >
              Contact us
                    </LinkWrapper>
                    <div className={s.footerSocial}>
                        <div className={s.footerSocialItem}>
                Telegram:{' '}
                            <LinkWrapper
                                className={s.link}
                                link="https://t.me/metasalience"
                            >
                                {' '}
                  @metasalience
                            </LinkWrapper>
                        </div>
                        <div className={s.footerSocialItem}>
                Telegram chat bot:{' '}
                            <LinkWrapper className={s.link} link="https://t.me/deftalk_bot">
                                {' '}
                  @deftalk_bot
                            </LinkWrapper>
                        </div>

                        <div className={s.footerSocialItems}>
                            <SocialLink
                                link="https://t.me/DeFinder_Capital_eng"
                                icon={<Telegram />}
                            />
                            <SocialLink
                                link="https://twitter.com/DFCapitalGroup"
                                icon={<Twitter />}
                            />
                        </div>
                    </div>
                </div>
                <Navigation />
            </div>
            <p className={s.footerCopyRight}>
          Copyright © 2024. All rights reserved. DF Capital group
            </p>
        </div>
    </footer>
)
