/* eslint-disable max-len */
import { IFaqCard } from '../components/faqList/faqCard'

export const FaqList: IFaqCard[] = [
    {
        title: 'Why TON?',
        description: 'At the end of 2023, the TON blockchain set a world record for transaction processing speed. They have a huge social base in the form of telegram users and endless growth prospects.'
    },
    {
        title: 'What problem does the project solve? What products do you offer? Price expectations?',
        description: 'DFC is primarily the social fan token of our DAO. Its price does not directly depend on the products; it is determined by the desire of the holders to have DFC in their wallets. Products are added on top of this add-on to meet the specific needs of the token holder community. Through this model, we connect the TON community into a single & flexible social ecosystem, independent of the token price or products for it.'
    },
    {
        title: 'At what price did private investors enter? What is their share in the project?',
        description: 'The DFC token was not sold in any private or public offerings, there are no hidden investors who would have an advantage in receiving the DFC token. Model of token distribution through airdrops and public placement on the market.'
    },
    {
        title: 'What are the benefits for DFC holders? Price expectations?',
        description: 'The DFC token will be integrated into all our products and services, including our telegram channels, games and wallet. Holders will have numerous benefits and as our DAO grows and our services become more popular, we hope to see prices ranging from $1 to $10 within a year. This assumption based solely on the growth rate of our community and the ratio to the demand for the token.'
    }
]
