/* eslint-disable import/no-default-export */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'

interface LinkWrapperProps {
    children: React.ReactNode
    link?: string
    className?: string
    isActive?: boolean
    forwardProps?: any
    style?: React.CSSProperties
}

// Wrap external link to <a> and internal link to router's <NavLink>
const LinkWrapper: FC<LinkWrapperProps> = ({
    children,
    link,
    className,
    forwardProps,
    style
}) => {
    const isLink = !!link
    const isExternalLink = !link?.startsWith('/')

    return isLink ? (
        isExternalLink ? (
            <a
                href={link}
                className={classNames(className)}
                target="_blank"
                {...forwardProps}
            >
                {children}
            </a>
        ) : (
            <NavLink to={link} className={classNames(className)} style={style} {...forwardProps}>
                {children}
            </NavLink>
        )
    ) : (
        <div className={classNames(className)} style={style} {...forwardProps}>
            {children}
        </div>
    )
}

export default LinkWrapper
