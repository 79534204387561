import { FC, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import Button, { btnVariant } from '../button'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../svgSelector'

import s from './faqList.module.sass'

export interface IFaqCard {
    title: string;
    description: string;
}

interface FaqCardProps extends IFaqCard {}

export const FaqCard: FC<FaqCardProps> = ({ title, description }) => {
    const [ showDescription, setShowDescription ] = useState<boolean>(false)

    return (
        <div className={s.card}>
            <div className={s.cardTop} onClick={() => setShowDescription(!showDescription)}>
                <p className={s.cardTopTitle}>{title}</p>
                <Button
                    variant={btnVariant.light}
                    className={`${s.cardActions} ${showDescription ? s.cardActionsActive : ''}`}
                    onClick={() => setShowDescription(!showDescription)}
                >
                    <SVGSelector type={SVG_SELECTOR_ICONS.chevronBottom} />
                </Button>
            </div>
            <AnimatePresence>
                {showDescription && (
                    <motion.p
                        className={s.cardDescription}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {description}
                    </motion.p>
                )}
            </AnimatePresence>
        </div>
    )
}
