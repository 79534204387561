/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import Marquee from 'react-fast-marquee'

interface TickerProps {
    children: React.ReactNode
    iterations?: number
    className?: any
}

const Ticker = ({ children, iterations = 12, className }: TickerProps) => {
    const arr = Array.apply(null, Array(iterations)).map(el => children)
    return (
        <div className={className}>
            <Marquee>{arr}</Marquee>
        </div>
    )
}

export default Ticker
