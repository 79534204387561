import React, { FC, useEffect, useRef } from 'react'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { v1 } from 'uuid'

import Button from '../button'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../svgSelector'

import 'swiper/css'
import 'swiper/css/pagination'

import s from './newsSlider.module.sass'

export type IDetailInfoItem = {
    title?: string | React.ReactNode;
    content?: string | React.ReactNode;
}

export interface INewsCardItem {
    id: string
    logo: string
    title: string
    description: string
    detailInfo: IDetailInfoItem[];
}

interface CardSliderProps {
    data: INewsCardItem[];
    card: React.ComponentType<INewsCardItem>;
    options?: SwiperProps;
    modules?: {
        name: string;
    }[]
    slidesPerView: number
}

export const NewsSlider: FC<CardSliderProps> = ({ data, card: CardComponent, options, slidesPerView, modules = [] }) => {
    const swiperOptions: SwiperProps = {
        ...options,
        navigation: {
            nextEl: '.paginationButtonNext2',
            prevEl: '.paginationButtonPrev2'
        },
        pagination: {
            type: 'bullets',
            clickable: true,
            enabled: true,
            bulletClass: 'bullets1',
            modifierClass: 'slider1'
        }
    }

    const paginationCounterRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (paginationCounterRef.current) {
            const pagination = document.querySelector('.slider1bullets')
            if (pagination) {
                paginationCounterRef.current.appendChild(pagination)
            }
        }
    }, [ data.length ])

    return (
        <>
            <Swiper
                navigation
                modules={[ Navigation, Pagination, ...modules ]}
                slidesPerView={slidesPerView}
                {...swiperOptions}
            >
                {data.map(el => (
                    <SwiperSlide key={v1()}>
                        <CardComponent {...el} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={s.pagination}>
                <div className={`${s.paginationInner} pagination`}>
                    <Button
                        className={`${s.paginationButton} paginationButtonPrev2`}
                    >
                        <SVGSelector type={SVG_SELECTOR_ICONS.chevronLeft} />
                    </Button>
                    <div ref={paginationCounterRef} className={`${s.paginationCounter} paginationCounter`}></div>
                    <Button
                        className={`${s.paginationButton} paginationButtonNext2`}
                    >
                        <SVGSelector type={SVG_SELECTOR_ICONS.chevronRight} />
                    </Button>
                </div>
            </div>
        </>
    )
}
