/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react'
import { ReactComponent as ArrowWhite } from '../../assets/img/icons/svg/ArrowWhite.svg'
import { ReactComponent as Document } from '../../assets/img/icons/svg/Document.svg'
import { ReactComponent as ArrowWhiteActions } from '../../assets/img/icons/svg/ArrowWhiteActions.svg'
import { ReactComponent as ActionBtn } from '../../assets/img/icons/svg/ActionBtn.svg'
import { ReactComponent as ChevronLeft } from '../../assets/img/icons/svg/ChevronLeft.svg'
import { ReactComponent as ChevronRight } from '../../assets/img/icons/svg/ChevronRight.svg'
import { ReactComponent as ChevronBottom } from '../../assets/img/icons/svg/ChevronBottom.svg'
import { ReactComponent as List } from '../../assets/img/icons/svg/List.svg'
import { ReactComponent as Cross } from '../../assets/img/icons/svg/Cross.svg'

export enum SVG_SELECTOR_ICONS {
    arrowWhite = 'arrowWhite',
    document = 'document',
    arrowWhiteActions = 'ArrowWhiteActions',
    actionBtn = 'ActionBtn',
    chevronLeft = 'chevronLeft',
    chevronRight = 'chevronRight',
    chevronBottom = 'chevronBottom',
    list = 'list',
    cross = 'cross'
}

// Icon mapping object
const icons: Record<SVG_SELECTOR_ICONS, React.ReactNode> = {
    [SVG_SELECTOR_ICONS.arrowWhite]: <ArrowWhite />,
    [SVG_SELECTOR_ICONS.document]: <Document />,
    [SVG_SELECTOR_ICONS.arrowWhiteActions]: <ArrowWhiteActions />,
    [SVG_SELECTOR_ICONS.actionBtn]: <ActionBtn />,
    [SVG_SELECTOR_ICONS.chevronLeft]: <ChevronLeft />,
    [SVG_SELECTOR_ICONS.chevronRight]: <ChevronRight />,
    [SVG_SELECTOR_ICONS.chevronBottom]: <ChevronBottom />,
    [SVG_SELECTOR_ICONS.list]: <List />,
    [SVG_SELECTOR_ICONS.cross]: <Cross />
}

interface SVGSelectorProps {
    type: SVG_SELECTOR_ICONS
    [x: string]: any
}

const SVGSelector: FC<SVGSelectorProps> = ({ type, ...rest }) => <>{React.cloneElement(icons[type] as React.ReactElement<any>, rest)}</>

export default SVGSelector
