/* eslint-disable import/no-default-export */
/* eslint-disable import/no-cycle */
import { FC, useState } from 'react'
import { v1 } from 'uuid'
import { useMediaQuery } from 'react-responsive'

import ExpandableCard from './card'
import ScrollableContainer from '../scrollableContainer'

import useGetContainerWidth from '../../hooks/useGetContainerWidth'

import style from './ExpandableCardSlider.module.sass'

export interface IExpandableCardItem {
    logo: string
    title: string
    desc: string
    image: string
    link: string
}

interface ExpandableCardSliderProps {
    list: IExpandableCardItem[]
}

const ExpandableCardSlider: FC<ExpandableCardSliderProps> = ({ list }) => {
    const [ expandedItem, setExpandedItem ] = useState<string | null>(null)

    const { paddingWidth } = useGetContainerWidth()

    const allExpandable = useMediaQuery({ query: '(max-width: 768px)' })

    return (
        <div className={style.expandableCardSliderWrapper}>
            <ScrollableContainer expandedItem={true} className={style.expandableCardSlider}>
                {list.map((item, index) => (
                    <ExpandableCard
                        key={v1()}
                        item={item}
                        index={index}
                        expanded={allExpandable ? true : expandedItem === item.title}
                        expandHandler={title => setExpandedItem(title)}
                        marginLeft={index === 0 ? paddingWidth : undefined}
                    />
                ))}
            </ScrollableContainer>
        </div>
    )
}

export default ExpandableCardSlider
