/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMediaQuery } from 'react-responsive'
import Ticker from '../../../../components/ticker'
import coin1 from './img/coin1.png'
import coin2 from './img/coin2.png'
import coin3 from './img/coin3.png'
import { ReactComponent as Capital } from '../../../../assets/img/logo/Capital.svg'
import HeaderBackground from './img/HeaderBackground.svg'
import style from './Head.module.sass'
// import CoinsBg from './img/Coins.png'
// import CoinsBgMobile from './img/CoinsMobile.png'

const HeadBlock = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    return (
        <header
            className={style.headBlock}
            // style={{ backgroundImage: `url(${isMobile ? CoinsBgMobile : CoinsBg})` }}
        >
            <div className={style.headBlockCoins}>
                <img src={coin1} />
                <img src={coin2} />
                <img src={coin3} />
            </div>
            <div
                className={style.heading}
                style={{ backgroundImage: `url(${HeaderBackground})` }}
            >
                <div className={style.content}>
                    <div className="container">
                        <h2 className={style.title}>DeFinder</h2>
                    </div>
                    <div className={style.capital}>
                        <Capital />
                    </div>
                </div>
            </div>
            <Ticker className={style.ticker}>
                <div className={style.tickerText}>DFC DAO alliance</div>
                <div className={style.tickerDivider}>•</div>
            </Ticker>
        </header>
    )
}

export default HeadBlock
