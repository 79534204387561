/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BrowserRouter } from 'react-router-dom'
import 'reset-css'
import './assets/styles/global.sass'
import { Suspense } from 'react'
import Routing from './Routing'
import SuspenseLoader from './components/suspenseLoader'

function App () {
    return (
        <BrowserRouter>
            <Suspense fallback={<SuspenseLoader />}>
                <Routing />
            </Suspense>
        </BrowserRouter>
    )
}

export default App
