/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import classNames from 'classnames'
import style from './Button.module.sass'
import LinkWrapper from '../linkWrapper'

export enum btnVariant {
    primary = 'primaryVariant',
    light = 'lightVariant',
    gradient = 'gradientVariant'
}

interface ButtonWrapperProps {
    children: React.ReactNode | string // Content to display inside the button
    link?: string // Button redirect link
}

const ButtonWrapper = ({ children, link }: ButtonWrapperProps) => {
    const isLink = !!link
    return isLink ? (
        <LinkWrapper className={style.buttonWrapper} link={link}>
            {children}
        </LinkWrapper>
    ) : (
        <div className={style.buttonWrapper}>{children}</div>
    )
}

interface ButtonProps {
    children: React.ReactNode | string // Content to display inside the button
    disabled?: boolean // Boolean flag to lock the button interactions
    variant?: btnVariant // Button appearance variant
    icon?: React.ReactNode | undefined // Button icon from enum
    link?: string // Button redirect link
    hasBorders?: boolean // flag to render button with/-out borders
    onClick?: () => any // button click handler
    className?: string | any
}

const Button = ({
    children,
    disabled = false,
    variant = btnVariant.primary,
    icon = null,
    link,
    hasBorders = false,
    onClick,
    className
}: ButtonProps) => (
    <ButtonWrapper link={link}>
        <button
            disabled={disabled}
            className={classNames(style.button, className, { [style[variant]]: true })}
            onClick={onClick}
        >
            {hasBorders && (
                <div
                    className={classNames(style.button, { [style[variant + 'Border']]: true })}
                />
            )}
            {icon}
            {children}
        </button>
    </ButtonWrapper>
)

export default Button
