import { FC } from 'react'

import { ICommunityCardItem } from '../communitySlider'

import DEFAULT_AVATAR from '../../assets/img/community/avatar.png'

import s from './communityCard.module.sass'

interface CommunityCardProps extends ICommunityCardItem {}

export const CommunityCard: FC<CommunityCardProps> = ({
    title,
    avatar = DEFAULT_AVATAR,
    text
}) => (
    <div className={s.card}>
        <div className={s.cardImg}>
            <img src={avatar ?? DEFAULT_AVATAR} alt="avatar" />
        </div>
        <p className={s.cardTitle}>{title}</p>
        <div className={s.cardDivider} />
        <p className={s.cardDescription}>{text}</p>
    </div>
)
