/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { FC, useEffect, useRef } from 'react'
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { v1 } from 'uuid'

import Button from '../button'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../svgSelector'

import 'swiper/css'
import 'swiper/css/pagination'

import s from './communitySlider.module.sass'

export interface ICommunityCardItem {
    title: string
    avatar?: string | null
    text: string
}

interface CardSliderProps {
    data: ICommunityCardItem[];
    card: React.ComponentType<ICommunityCardItem>;
    options?: SwiperProps;
    modules?: {
        name: string;
    }[]
    slidesPerView: number
}

export const CommunitySlider: FC<CardSliderProps> = ({ data, card: CardComponent, options, slidesPerView, modules = [] }) => {
    const swiperOptions: SwiperProps = {
        ...options,
        navigation: {
            nextEl: '.paginationButtonNext',
            prevEl: '.paginationButtonPrev'
        },
        pagination: {
            type: 'bullets',
            clickable: true,
            enabled: true,
            bulletClass: 'bullets2',
            modifierClass: 'slider2'
        },
        onInit: (swiper: SwiperClass) => swiper.activeIndex = 1
    }

    const paginationCounterRef2 = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (paginationCounterRef2.current) {
            const pagination = document.querySelector('.slider2bullets')
            if (pagination) {
                paginationCounterRef2.current.appendChild(pagination)
            }
        }
    }, [ data.length ])

    return (
        <>
            <Swiper
                navigation
                modules={[ Navigation, Pagination, ...modules ]}
                slidesPerView={slidesPerView}
                {...swiperOptions}
            >
                {data.map(el => (
                    <SwiperSlide key={v1()}>
                        <CardComponent {...el} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={s.pagination}>
                <div className={`${s.paginationInner}`}>
                    <Button
                        className={`${s.paginationButton} paginationButtonPrev`}
                    >
                        <SVGSelector type={SVG_SELECTOR_ICONS.chevronLeft} />
                    </Button>
                    <div ref={paginationCounterRef2} className={`${s.paginationCounter2} paginationCounter2`}></div>
                    <Button
                        className={`${s.paginationButton} paginationButtonNext`}
                    >
                        <SVGSelector type={SVG_SELECTOR_ICONS.chevronRight} />
                    </Button>
                </div>
            </div>
        </>
    )
}
