/* eslint-disable import/no-default-export */
/* eslint-disable import/no-cycle */
import { FC } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import style from './ExpandableCardSlider.module.sass'
import { IExpandableCardItem } from './indext'
import { ReactComponent as DFCLogo } from '../../assets/img/logo/DFC logo.svg'
import Button, { btnVariant } from '../button'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../svgSelector'

interface ExpandableCardProps {
    item: IExpandableCardItem
    index: number
    expandHandler: (title: string) => void
    expanded: boolean
    marginLeft?: number | string
}

const CollapsedCard = ({
    item,
    index,
    onClick
}: {
    item: IExpandableCardItem
    index: string
    onClick: () => void
}) => (
    <div className={style.collapsedCard} onClick={onClick}>
        <div className={style.index}>{index}</div>
        <div className={style.collapsedCardStroke}></div>
        <div className={style.collapsedCardLogo}>
            <img src={item.logo} alt={item.title} />
        </div>
    </div>
)

const ExpandedCard = ({
    item,
    index
}: {
    item: IExpandableCardItem
    index: string
}) => (
    <motion.div
        className={style.expandedCard}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
    >
        <div className={style.expandedCardContent}>
            <div className={style.expandedCardMain}>
                <div className={style.expandedCardHeader}>
                    <span className={style.index}>{index}</span>
                    <DFCLogo />
                </div>
                <p className={style.expandedCardTitle}>{item.title}</p>
                <p className={style.expandedCardDesc}>{item.desc}</p>
            </div>
            <div className={style.expandedCardFooter}>
                <img src={item.logo} alt={item.title} />
                <div className={style.expandedCardBtn}>
                    <Button
                        link={item.link}
                        icon={<SVGSelector type={SVG_SELECTOR_ICONS.arrowWhite} />}
                        variant={btnVariant.light}
                    >
              Open
                    </Button>
                </div>
            </div>
        </div>
        <div
            className={style.expandedCardImg}
            style={{ backgroundImage: `url(${item.image})` }}
        />
        {/* <img src={item.image} className={style.expandedCardImg} /> */}
    </motion.div>
)

const ExpandableCard: FC<ExpandableCardProps> = ({
    item,
    index,
    expandHandler,
    expanded = false,
    marginLeft
}) => {
    const indexString = (index + 1 < 10 ? '0' : '') + '' + (index + 1)

    return (
        <div
            className={classNames(style.expandableCard, { [style.expanded]: expanded })}
            style={{ marginLeft }}
        >
            {expanded ? (
                <ExpandedCard item={item} index={indexString} />
            ) : (
                <CollapsedCard
                    item={item}
                    index={indexString}
                    onClick={() => expandHandler(item.title)}
                />
            )}
        </div>
    )
}

export default ExpandableCard
