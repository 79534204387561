/* eslint-disable import/no-default-export */
import React, { CSSProperties, FC, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useGesture } from 'react-use-gesture'
import { v1 } from 'uuid'
import style from './TokenomicsCards.module.sass'
import TokenomicsCard, { TokenomicsCardConfig } from './card'

interface TokenomicsCardsProps {
    config: TokenomicsCardConfig[];
}

const inactiveStyles: CSSProperties = {
    zIndex: 0,
    transform: 'scale(0.85)',
    opacity: 1
}

const TokenomicsCards: FC<TokenomicsCardsProps> = ({ config }) => {
    const [ activeIndex, setActiveIndex ] = useState(0)

    const itemClickHandler = (index: number): void => {
        setActiveIndex(index)
    }

    const isOverlappingCards = useMediaQuery({ query: '(min-width: 1400px)' })

    const bind = useGesture({
        onDrag: ({ offset: [ x ] }) => {
            const newIndex = Math.max(
                0,
                Math.min(
                    activeIndex + (x > 0 ? -1 : 1),
                    config.length - 1
                )
            )
            setActiveIndex(newIndex)
        }
    })

    return (
        <div className={style.tokenomicsCards} {...(isOverlappingCards ? bind() : {})}>
            {config.map((item, i) => (isOverlappingCards ? (
                <div
                    className={style.itemWrapper}
                    onClick={() => itemClickHandler(i)}
                    style={i !== activeIndex ? inactiveStyles : undefined}
                    key={v1()}
                >
                    <TokenomicsCard {...item} isActive={i === activeIndex} />
                </div>
            ) : (
                <TokenomicsCard key={v1()} {...item} isActive={i === activeIndex} />
            )))}
        </div>
    )
}

export default TokenomicsCards
