/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { FC, MouseEvent, useState } from 'react'
import classNames from 'classnames'
import { v1 } from 'uuid'

import { roadmapList } from '../../../../configs/roadmapList'

import { NavRef } from '../Footer/navigation'

import s from './Roadmap.module.sass'

export const RoadmapRef: NavRef = { current: null }

interface RoadmapProps {}

export interface IRoadmapItem {
    status: boolean
    stage: string
    title: string
    description: string
}

interface RoadmapItemProps extends IRoadmapItem {
    lastElement: boolean
}

const RoadmapItem: FC<RoadmapItemProps> = ({
    description,
    stage,
    status,
    title,
    lastElement
}) => (
    <div className={s.item}>
        <div className={s.itemRoadmap}>
            <div className={s.itemRoadmapDot} style={{ background: status ? '#10B981' : '' }}></div>
            {!lastElement && <div className={`${s.itemRoadmapRoad} ${status ? s.itemRoadmapRoadDone : ''}`} />}
        </div>
        <div className={s.itemBody} style={{ paddingRight: lastElement ? 0 : '' }}>
            <div className={`${s.itemStage} ${status ? s.itemStageActive : ''}`}>
                {stage}
            </div>
            <p className={s.itemTitle}>
                {title}
            </p>
            <p className={s.itemDescription}>
                {description}
            </p>
        </div>
    </div>
)

export const Roadmap: FC<RoadmapProps> = () => {
    const [ isScrolling, setIsScrolling ] = useState<boolean>(false)
    const [ startX, setStartX ] = useState<number>(0)
    const [ scrollLeft, setScrollLeft ] = useState<number>(0)

    const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    // trigger scrolling start
        setIsScrolling(true)
        setStartX(event.clientX)
        setScrollLeft(event.currentTarget.scrollLeft)
    }

    const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    // get mouse move distance and add it to scroll to simulate grabbing
        if (!isScrolling) return
        const deltaX = startX - event.clientX
        event.currentTarget.scrollLeft = scrollLeft + deltaX
    }

    const handleMouseUp = () => {
        setIsScrolling(false)
    }

    const handleMouseLeave = () => {
        setIsScrolling(false)
    }

    return (
        <section className={s.roadmap} id="roadmap" ref={RoadmapRef}>
            <div className="container">
                <p className={classNames(s.title, 'sectionTitle')}>
          Roadmap
                </p>
                <div className={s.decorI}></div>
                <div
                    className={s.contentWrapper}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                >
                    {roadmapList.map((el, index) => (
                        <RoadmapItem key={v1()} lastElement={index === roadmapList.length - 1} {...el} />
                    ))}
                </div>
            </div>
        </section>
    )
}
