import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import Button, { btnVariant } from '../button'
import { INewsCardItem } from '../newsSlider'

import SVGSelector, { SVG_SELECTOR_ICONS } from '../svgSelector'

import s from './newsCard.module.sass'

interface NewsCardProps extends INewsCardItem {}

export const NewsCard: FC<NewsCardProps> = ({
    id,
    description,
    logo,
    title
}) => {
    const navigate = useNavigate()

    return (
        <div className={s.card}>
            <div className={s.cardImg}>
                <img src={logo} alt={title} />
            </div>
            <div className={s.cardBody}>
                <p className={s.cardTitle}>{title}</p>
                <div className={s.cardDivider} />
                <p className={s.cardDescription}>{description.length > 147 ? description.slice(0, 147) + '...' : description}</p>
            </div>
            <Button
                variant={btnVariant.light}
                className={s.cardButton}
                onClick={() => navigate(`/news/${id}`)}
            >
        Read
                <SVGSelector type={SVG_SELECTOR_ICONS.arrowWhiteActions} />
            </Button>
        </div>
    )
}
