/* eslint-disable import/no-default-export */
/* eslint-disable no-param-reassign */
import React, { MouseEvent, ReactNode, useRef, useState } from 'react'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import SVGSelector, { SVG_SELECTOR_ICONS } from '../svgSelector'

import style from './ScrollableContainer.module.sass'

interface ScrollableContainerProps {
    children: ReactNode
    className?: any
    expandedItem?: boolean
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
    children,
    className,
    expandedItem
}) => {
    const [ isScrolling, setIsScrolling ] = useState<boolean>(false)
    const [ startX, setStartX ] = useState<number>(0)
    const [ scrollLeft, setScrollLeft ] = useState<number>(0)
    console.log('🚀 ~ scrollLeft:', scrollLeft)
    const ref = useRef<HTMLDivElement>(null)

    const isTablet = useMediaQuery({ query: '(max-width: 768px)' })

    const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
        // Trigger scrolling start
        setIsScrolling(true)
        setStartX(event.clientX)
        setScrollLeft(event.currentTarget.scrollLeft)
    }

    const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
        // Get mouse move distance and add it to scroll to simulate grabbing
        if (!isScrolling) return
        const deltaX = startX - event.clientX
        const element = ref.current! as HTMLDivElement
        element.scrollTo({
            left: scrollLeft + deltaX,
            behavior: 'smooth'
        })
        setScrollLeft(scrollLeft + deltaX)
    }

    const handleMouseUp = () => {
        setIsScrolling(false)
    }

    const handleMouseLeave = () => {
        setIsScrolling(false)
    }

    const handleScrollToRight = () => {
        const element = ref.current! as HTMLDivElement
        element?.scrollTo({ left: element.scrollLeft + 600, behavior: 'smooth' })
        setScrollLeft(element.scrollLeft + 600)
    }

    const handleScrollToLeft = () => {
        const element = ref.current! as HTMLDivElement
        element?.scrollTo({ left: element.scrollLeft - 600, behavior: 'smooth' })
    }

    return (
        <div
            style={{ userSelect: isScrolling ? 'none' : undefined }}
            className={style.scrollableContainerWrapper}
        >
            <div
                style={{ userSelect: isScrolling ? 'none' : undefined }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                className={classNames(className, style.scrollableContainer)}
                ref={ref}
            >
                {children}
                {!isTablet && (
                    <SVGSelector
                        type={SVG_SELECTOR_ICONS.chevronRight}
                        className={style.scrollableContainerBtn}
                        onClick={handleScrollToRight}
                    />
                )}
                {!isTablet && scrollLeft > 1 && expandedItem && (
                    <SVGSelector
                        type={SVG_SELECTOR_ICONS.chevronLeft}
                        className={style.scrollableContainerBtnLeft}
                        onClick={handleScrollToLeft}
                    />
                )}
            </div>
        </div>
    )
}

export default ScrollableContainer
