/* eslint-disable import/no-default-export */
import style from './SuspenseLoader.module.sass'
import { ReactComponent as DFCLogo200px } from './img/DFCLogo200px.svg'
import RadialBg from './img/RadialBg.svg'

const SuspenseLoader: React.FC = () => (
    <div
        className={style.suspenseLoader}
        style={{ backgroundImage: `url(${RadialBg})` }}
    >
        <DFCLogo200px />
        <div className={style.decor}></div>
    </div>
)

export default SuspenseLoader
