/* eslint-disable import/no-default-export */
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { useMediaQuery } from 'react-responsive'

import style from './TonPotential.module.sass'

import COIN from '../../../../assets/img/ton-potential/coin.png'
import DIAMOND_SMALL from '../../../../assets/img/ton-potential/diamond-small.png'
import DIAMOND_BIG from '../../../../assets/img/ton-potential/diamond-big.png'
import Button from '../../../../components/button'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../../../../components/svgSelector'

const TonPotential: React.FC = () => {
    const [ showMore, setShowMore ] = useState<boolean>(false)

    const isMobile = useMediaQuery({ query: '(max-width: 769px)' })

    useEffect(() => {
        if (!isMobile) {
            setShowMore(true)
        } else {
            setShowMore(false)
        }
    }, [ isMobile ])

    return (
        <div className={style.tonPotential}>
            <div className="container">
                <div style={{ position: 'relative' }}>
                    <p className={classNames(style.title, 'sectionTitle')}>
            Expanding <br />
                        <span className={'textBlueGradient'}>TON potential</span>
                    </p>
                    <img
                        src={DIAMOND_BIG}
                        alt="diamond big"
                        className={style.diamondBig}
                    />
                    <img src={COIN} alt="coin" className={style.coin} />
                    <img
                        src={DIAMOND_SMALL}
                        alt="diamond small"
                        className={style.diamondSmall}
                    />
                    <div className={style.contentWrapper}>
                        <div className={style.dot}>
                            <div></div>
                        </div>
                        <motion.div
                            className={style.leftSide}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <p className={style.text}>
                We see the huge potential of the TON blockchain and have chosen
                to grow with it. We are bringing a new people to the TON
                community, developing our social networks and services,
                primarily in telegram, as the main platform that TON itself is
                aimed at.
                                <br />
                                <br />
                We popularize it for our users, teach them how to work with TON
                and show their prospects. Currently, services and communities in
                the TON network are unsystematic and fragmented. The TON team
                solves this problem setting a goal to encourage users, running
                many promotions to grow by 5 millions users in 12 months.
                                <br />
                                <br />
                The main idea and task of our DF Capital is to become the number
                one community in TON, to create a synergy effect within it
                through popularization and filling missing niches, services and
                products. On top of the TON blockchain we launched the DFC
                token, which will socially connect all participants in this
                crypto sector into something more integral.
                                <br />
                                <br />
                Then as our reach grows, we’ll look at what new products that
                community needs right now and in the future, look for their
                needs. Our approach is that a product without users is nothing,
                and with a base of motivated and expirienced DAO members, we can
                develop any products they need with success.
                                <br />
                                <br />
                Several TON & market services are already created to demonstrate
                our capabilities and approach, as well as to continue recruiting
                and training the community. The DFC token is the basis for
                uniting people through the DAO, the connecting link in these
                processes. The final goal, as we see our development, is to
                become within TON network the same major player with related
                ecosystem services as Binance once became for the entire crypto
                market and led it to new heights, uniting previously disparate
                members of the crypto community.
                            </p>
                        </motion.div>
                        <motion.div
                            className={`${style.rightSide} ${style.rightSideMobile}`}
                            initial={{ opacity: 0, height: 0 }}
                            animate={{
                                opacity: showMore ? 1 : 0,
                                flexBasis: showMore ? '48%' : 'inherit'
                            }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className={style.text}>
                                <div className={style.decorI}></div>
                                <p className={style.textFloated}>
                  We are firstly solving the problem of fragmentation and voids
                  within the TON blockchain groups, which is obviously gaining
                  popularity for us. DF Capital alliance will use part of the
                                </p>
                DFC token supply from social pool to attract new participants
                and motivate them to work together. This will also cause general
                interest in the stability of the DFC token rate.
                                <br />
                                <br />
                We see the potential of the TON blockchain and want to join and
                help him succeed. Specific products will be created to meet the
                specific needs of the emerging community. We already have
                proposals from our DAO. The DFC token itself is now deliberately
                not officially loaded with any utilities, which will allow us to
                flexibly maneuver to suit the changing market.
                                <br />
                                <br />
                DFC is now the glue for social interaction among all TON user
                base. The first steps and products that are planned are outlined
                in our Roadmap. Our research has shown that now many products do
                not even have to be too technically complex, what is more
                important is their relevance and correct presentation to a
                large, prepared community.
                            </div>
                        </motion.div>
                    </div>
                    {isMobile && !showMore && <div className={style.decor} />}
                    {isMobile && (
                        <Button
                            className={`${style.btn} ${showMore ? style.btnActive : ''}`}
                            onClick={() => setShowMore(!showMore)}
                        >
                            <SVGSelector type={SVG_SELECTOR_ICONS.chevronBottom} />
                            {!showMore ? 'Show More' : 'Hide'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TonPotential
