/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-default-export */
import React, { CSSProperties, Ref, forwardRef, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import style from './Navigation.module.sass'
import LinkWrapper from '../linkWrapper'
import DeFinderCapitalLogoWhite1 from '../../assets/img/logo/DeFinderCapitalLogoWhite1.png'
import navigationConfig, {
    INavigationItemNoChildren,
    INavigationItemWithChildren
} from '../../configs/navigationConfig'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../svgSelector'
import Button, { btnVariant } from '../button'
import { AboutUsRef } from '../../pages/Main/pageBlocks/Whitepaper'
import { ProductsRef } from '../../pages/Main/pageBlocks/Products'
import { PartnersRef } from '../../pages/Main/pageBlocks/TrustedPartners'
import { NewsRef } from '../../pages/Main/pageBlocks/News'
import { RoadmapRef } from '../../pages/Main/pageBlocks/Roadmap'
import { FaqRef } from '../../pages/Main/pageBlocks/Faq'

interface NavigationItemLinkProps extends INavigationItemNoChildren {
    className?: any
    st?: React.CSSProperties
}

const NavigationItemLink = ({
    title,
    to,
    className,
    st
}: NavigationItemLinkProps) => (
    <LinkWrapper link={to} className={classNames(style.itemLink, className)} style={st}>
        {title}
    </LinkWrapper>
)

interface NavigationItemProps extends INavigationItemWithChildren {
    isMobile?: boolean
    isActive: boolean
    setClose: React.Dispatch<React.SetStateAction<boolean>>
}

const NavigationItem = forwardRef<HTMLDivElement, NavigationItemProps>(
    ({ title, to, children, isMobile, isActive, setClose }: NavigationItemProps, ref: Ref<HTMLDivElement>) => {
        const [ expanded, setExpanded ] = useState(false)
        const hasChildren = !!children
        const node = useRef<HTMLDivElement>(null)

        const handleClickOutside = (event: MouseEvent) => {
            if (node.current && !node.current.contains(event.target as Node)) {
                setExpanded(false)
            }
        }

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [])

        const hoverHandler = (state: boolean) => {
            if (isMobile) return
            setExpanded(state)
        }

        const clickHandler = () => {
            if (!isMobile) return
            setExpanded(!expanded)
            setClose(false)
        }

        return (
            <div
                ref={node}
                className={classNames(style.item, { [style.itemMobile]: isMobile })}
                onMouseEnter={() => hoverHandler(true)}
                onMouseLeave={() => hoverHandler(false)}
            >
                <div className={style.itemWrapper} onClick={clickHandler}>
                    <NavigationItemLink
                        title={title}
                        to={to}
                        className={{ [style.itemLinkActive]: expanded }} st={{ color: isActive ? '#fff' : '' }}
                    />
                    {hasChildren ? (
                        <SVGSelector
                            type={SVG_SELECTOR_ICONS.chevronBottom}
                            onClick={() => setExpanded(!expanded)}
                            style={{ transform: expanded ? 'rotate(180deg)' : undefined }}
                        />
                    ) : null}
                </div>
                {hasChildren && expanded && (
                    <div className={classNames(style.itemDD, { [style.itemDDMobile]: isMobile })}>
                        {children.map(item => (
                            <div className={style.itemDDChild} key={item.title}>
                                <NavigationItemLink st={{ color: isActive ? '#fff' : '' }} {...item} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
)

const Navigation: React.FC = () => {
    const isMobileNav = useMediaQuery({ query: '(max-width: 1400px)' })
    const isNeedBtns = useMediaQuery({ query: '(max-width: 768px)' })

    const [ activeSection, setActiveSection ] = useState<string>('')

    const [ expanded, setExpanded ] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
            const offset = 250
            const sections = [
                { ref: AboutUsRef, name: 'About us' },
                { ref: ProductsRef, name: 'Products' },
                { ref: PartnersRef, name: 'Partners' },
                { ref: NewsRef, name: 'NEWS' },
                { ref: RoadmapRef, name: 'Roadmap' },
                { ref: FaqRef, name: 'FAQ' }
            ]

            const currentSection = sections.find((section) => {
                if (section.ref && section.ref.current) {
                    const sectionTop = section.ref.current.offsetTop
                    const sectionBottom = sectionTop + section.ref.current.offsetHeight
                    return window.scrollY >= (sectionTop - offset) && window.scrollY < (sectionBottom - offset)
                }
                return false
            })

            setActiveSection(currentSection ? currentSection.name : '')
        }

        handleScroll()

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [ AboutUsRef, ProductsRef, PartnersRef, NewsRef, RoadmapRef, FaqRef ])

    useEffect(() => {
        if (expanded) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [ expanded ])

    return (
        <nav className={style.navigation}>
            {isMobileNav && expanded && (
                <div className={style.navigationMobile}>
                    <div className={style.navigationMobileHeader}>
                        <img
                            className={style.logo}
                            src={DeFinderCapitalLogoWhite1}
                            alt="DeFinder Capital"
                            onClick={() => navigate('/')}
                        />
                        <SVGSelector
                            type={SVG_SELECTOR_ICONS.cross}
                            onClick={() => setExpanded(false)}
                        />
                    </div>
                    <div className={style.navigationMobileList}>
                        {navigationConfig.links.map(({ ref, ...item }) => (
                            <NavigationItem key={item.title} {...item} setClose={setExpanded} isActive={activeSection === item?.title} isMobile />
                        ))}
                    </div>
                    {isNeedBtns && (
                        <div className={style.navigationMobileBtns}>
                            <Button
                                link="https://t.me/metasalience"
                                variant={btnVariant.primary}
                                className={`${style.navigationMobileBtn} ${style.btnContacts}`}
                            >
                                Contact Us
                            </Button>
                            <Button
                                link="https://www.mexc.com/exchange/DFC_USDT"
                                variant={btnVariant.gradient}
                                className={`${style.navigationMobileBtn} ${style.btnBuy}`}
                            >
                                Buy DFC
                            </Button>
                        </div>
                    )}
                </div>
            )}
            {isMobileNav ? (
                <>
                    <SVGSelector
                        type={SVG_SELECTOR_ICONS.list}
                        onClick={() => setExpanded(true)}
                    />
                </>
            ) : (
                navigationConfig.links.map(({ ref, ...item }) => (
                    <NavigationItem
                        key={item.title}
                        {...item}
                        isActive={activeSection === item?.title}
                        setClose={setExpanded}
                        isMobile
                    />
                ))
            )}

        </nav>
    )
}

export default Navigation
