/* eslint-disable import/no-default-export */
/* eslint-disable import/no-cycle */
import { FC } from 'react'
import classNames from 'classnames'

import ExpandableCardSlider from '../../../../components/expandableCardSlider/indext'

import { productsList } from '../../../../configs/productsList'

import { NavRef } from '../Footer/navigation'

import style from './Products.module.sass'

export const ProductsRef: NavRef = { current: null }

const ProductsBlock: FC = () => (
    <section className={style.productsBlock} id="products" ref={ProductsRef}>
        <div className="container">
            <p className={classNames(style.title, 'sectionTitle')}>Products</p>
        </div>
        <ExpandableCardSlider list={productsList} />
    </section>
)

export default ProductsBlock
