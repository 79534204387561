/* eslint-disable max-len */
/* eslint-disable no-irregular-whitespace */
import first from '../assets/img/news/01.jpg'
import second from '../assets/img/news/02.jpg'
import secondTwo from '../assets/img/news/021.jpg'
import third from '../assets/img/news/03.png'
import thirdTwo from '../assets/img/news/031.jpg'
import fourth from '../assets/img/news/04.png'
import { IDetailInfoItem, INewsCardItem } from '../components/newsSlider'

const FirstDetailInfo: IDetailInfoItem[] = [
    { content: <img src={first} alt="DeFinder and $DFC token" /> },
    { title: '$DFC token', content: <p>$DFC (DeFinder Capital ecosystem token) is the first DAO/FAN token and the main token of the ecosystem of projects on the TON blockchain with more than 100+ services on one platform. Traded on <a href='https://www.mexc.com/uk-UA/exchange/DFC_USDT' target="_blank">MEXC</a>, the main liquidity is still there.</p> },
    { content: <p>According to the project, they have a direct connection with the TON Foundation. Earlier, the head of the TON Foundation, Andrey Rogozov, made a <a href="https://tonviewer.com/transaction/8dbdd92cebfa558a43c8403d1918dde472fc405486d6c764bd8627ac3430085c" target="_blank">transfer</a> of $20,000 in $TON tokens to the account of the DeFinder foundation, and where is the TON Foundation, there is Pavel Durov, there are even <a href="https://www.publish0x.com/cryptonews-blog/why-i-bought-the-dollar-dfc-token-the-best-project-of-the-to-xxvvqjz" target="_blank">rumors</a> that the project is being cut by his team.</p> },
    { content: <p>Now the $DFC token is in the top 500 of <a href="https://coinmarketcap.com/ru/currencies/definder-capital/" target='_blank'>CoinMarketCap</a> and in the top 2 tokens of the <a href="https://coinmarketcap.com/ru/view/toncoin-ecosystem/" target='_blank'>Toncoin</a> ecosystem, second only to the main token $TON. The market capitalization is $65 million, and the daily trading volume is an order of magnitude higher than that of other tokens in the TON ecosystem, so if you wanted to buy something from the TON ecosystem before the bull, then in addition to $TON itself, you can also pay attention to $DFC .</p> },
    { content: <p>The token is traded on well-known DEXs in the Toncoin ecosystem: <a href="https://app.ston.fi/swap?chartVisible=false&amp%3Bft=TON&amp%3Btt=DFC&ft=TON&tt=STON" target='_blank'>Ston.Fi</a> and <a href="https://dedust.io/pools/EQCEho8oSvzVneM-q3ALV9GMOoRzlGNwrGtq4p2x3SnInMVA" target='_blank'>Dedust</a> and on the centralized exchange <a href="https://www.mexc.com/login?inviteCode=1ahhu" target='_blank'>MEXC</a>. You can also buy the token directly on Telegram via <a href="https://t.me/wallet" target='_blank'>Wallet</a> or <a href="https://t.me/xrocket/cex" target='_blank'>xRocket</a>.</p> },
    { content: <p>There should be listings on other major exchanges soon, but it is still a mystery which ones. After all, their market maker is DWF (those who pushed a large number of tokens on Binance), who acts as a conductor in these negotiations.</p> }
]

const SecondDetailInfo: IDetailInfoItem[] = [
    { content: <img src={second} alt="TONs Largest Ecosystem" /> },
    {  title: 'TONs Largest Ecosystem', content: <p>Today we will talk about <a href="https://definder.club/" target="_blank">DeFinder Capital</a> (also DF Capital, or DFC) and its token $DFC, which a lot of users are anticipating can surpass DOGE and BONK in popularity and become the engine of success of the TON ecosystem. $DFC is the main token of the ecosystem of projects on the TON blockchain with more than 100+ services on one platform. The project has an audience of more than 250,000 people.</p> },
    { content: <p>DFC (DeFinder Capital) is a Web 3.0 company building an exciting ecosystem on the TON blockchain. Their big idea is to roll out various services and products, all connected through their own $DFC token. This move is about getting people more involved and creating a strong sense of community within the TON space.</p> },
    { content: <p>DeFinder has smartly designed its platform to boost the DFC token’s usefulness and market presence, tapping into the power of community-led growth. The endgame for DeFinder? To make DFC a major player in the TON ecosystem and to stand out as a top project in the blockchain world. Just so you know, the TON blockchain is all about a decentralized and open internet, a brainchild of the tech folks at Telegram.</p> },
    { title: 'What is $DFC token?', content: <img src={secondTwo} alt="What is $DFC token?" /> },
    { content: <p>DFC is the central token of DeFinder, a dynamic token that connects a wide array of services and games within the TON blockchain. More than just a digital coin, it’s the lifeblood of the DeFinder community, enriching every interaction and transaction on the platform. As DeFinder expands, DFC’s role grows too, increasing its utility and importance in the TON ecosystem. It’s designed to be adaptable and valuable, playing a key part in the platform’s evolution and offering users a unique and engaging experience.</p> },
    { content: <p>DFC is the central token of DeFinder, a dynamic token that connects a wide array of services and games within the TON blockchain. More than just a digital coin, it’s the lifeblood of the DeFinder community, enriching every interaction and transaction on the platform. As DeFinder expands, DFC’s role grows too, increasing its utility and importance in the TON ecosystem. It’s designed to be adaptable and valuable, playing a key part in the platform’s evolution and offering users a unique and engaging experience.</p> },
    { content: <p>DeFinder Capital should become the flagship product on the TON blockchain both in terms of capitalization and number of users. Given the huge popularity of Telegram and all projects based on it (Notcoin is a typical example), it is quite expected that the DFC rate will soar to $10 and higher, but for this the developers should actively develop their project. </p> }
]

const ThirdDetailInfo: IDetailInfoItem[] = [
    { content: <img src={third} alt="DFC" /> },
    { title: 'The best project to invest in 2024 overall', content: <p>DeFinder Capital is the best altcoin you can buy in 2024. DFC is also the largest project on the TON blockchain and is even better than PEPE. For the past few months this project has been at the top of our ranking and now we will explain why.</p> },
    { content: <p>DFC is currently one of the leaders of the TON ecosystem in terms of TVL, their token trades on MEXC and top DEX at 10x of launch. DFC is also integrated into the official wallet of Telegram – @wallet, which is an indicator of the project’s reliability. Amidst their growing popularity came rumors of ties to Binance and DWF Labs.</p> },
    { content: <p>DFC DeFinder project holds great promise in the TON ecosystem, and its close relationship with the developers of TON and Notcoin underscores its importance. <a href="https://medium.com/@JoshCryptoBard/how-to-make-money-with-telegram-projects-3bb83af6f9db" target="_blank">Insiders</a> were the first to pay attention to the project, and they began their own investigations: looking for the project’s connection with the TON team, finding <a href="https://tonviewer.com/transaction/8dbdd92cebfa558a43c8403d1918dde472fc405486d6c764bd8627ac3430085c" target='_blank'>public transfers</a> from the head of the TON Foundation to the wallet of the DeFinder Foundation. There are already dozens of investigative articles on the Internet, we suggest you read them. </p> },
    { content: <p>TON, a prominent player in the blockchain industry, is actively promoting DFC on all of its platforms: DFC is the most trending token on TON and 4th among all projects on the blockchain, with more than 15,000 holders.</p> },
    { content: <img src={thirdTwo} alt="DFC" /> },
    { content: <p>DeFinder is currently the leading project in the TON ecosystem, with a large community of more than 400 thousand users (if we focus on the indicators in the <a href="https://t.me/DeFinder_Capital_eng" target="_blank" rel="noopener noreferrer">Telegram channels</a>). It is worth mentioning that DeFinder is the project of Pavel Durov’s team, as many <a href="https://www.publish0x.com/cryptonews-blog/why-i-bought-the-dollar-dfc-token-the-best-project-of-the-to-xxvvqjz" target="_blank" rel="noopener noreferrer">market insiders</a> say. Here we see the same scheme as on the Solana blockchain – it was promoted on the market with the help of loud meme projects (DOGE, BONK, etc.). The main task is to rock the projects to attract as many investors as possible. Here we see the same plan – Blockchain TON promotes the DeFinder project to take the leading position in the market, where the main flow of users will come from. </p> }
]

const FourthDetailInfo: IDetailInfoItem[] = [
    { content: <img src={fourth} alt="DFC" /> },
    { title: '$DFC price prediction for 2024 and 2025', content: <p>DFC, DF Capital or DeFinder Capital is the hero of today’s review. DFC, backed by TON and Pavel Durov, is not only the main Notcoin competitor, but also a promising venture within the TON blockchain with 300,000 community, huge 2.2 million dollars TVL on DeDust and Ston.fi, functional products, and a clear vision. </p> },
    { content: <p>Positioned as the most extensive ecosystem of projects on the TON blockchain, DeFinder Capital hosts over 100 services on a unified platform, including Arbuz and Kingu. </p> },
    { content: <p>Positioned as the most extensive ecosystem of projects on the TON blockchain, DeFinder Capital hosts over 100 services on a unified platform, including Arbuz and Kingu. </p> }
]

export const newsList: INewsCardItem[] = [
    {
        id: '01',
        logo: first,
        title: '$DFC token',
        description: 'A trust resource that we have been working with for a long time. an active audience, which can then independently spread rumors about Durov’s involvement in the project. the channel that can be referenced in other posts. post without tag and deletion, channel style',
        detailInfo: FirstDetailInfo
    },
    {
        id: '02',
        logo: second,
        title: 'TONs Largest Ecosystem',
        description: 'Today we will talk about DeFinder Capital (also DF Capital, or DFC) and its token $DFC, which a lot of users are anticipating can surpass DOGE and BONK in popularity and become the engine of success of the TON ecosystem.',
        detailInfo: SecondDetailInfo
    },
    {
        id: '03',
        logo: third,
        title: 'The best project to invest',
        description: 'DeFinder Capital is the best altcoin you can buy in 2024. DFC is also the largest project on the TON blockchain and is even better than PEPE. For the past few months this project has been at the top of our ranking and now we will explain why.',
        detailInfo: ThirdDetailInfo
    },
    {
        id: '04',
        logo: fourth,
        title: '$DFC price prediction',
        description: 'DFC, DF Capital or DeFinder Capital is the hero of today’s review. DFC, backed by TON and Pavel Durov, is not only the main Notcoin competitor, but also a promising venture within the TON blockchain with 300,000 community, huge 2.2 million dollars TVL on DeDust and Ston.fi, functional products, and a clear vision. ',
        detailInfo: FourthDetailInfo
    }
]
