import { FC, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import Navbar from '../../components/navbar'
import { IDetailInfoItem } from '../../components/newsSlider'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../../components/svgSelector'

import { Footer } from '../Main/pageBlocks/Footer'
import { News, NewsRef } from '../Main/pageBlocks/News'

import { newsList } from '../../configs/newsList'

import s from './NewsPage.module.sass'

interface NewsPageProps {}

export const NewsPage: FC<NewsPageProps> = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const [ data, setData ] = useState<IDetailInfoItem[] | undefined>(undefined)

    useEffect(() => {
        if (!id) navigate('/')

        window.scrollTo(0, 0)

        setData(newsList.find(el => el.id === id)?.detailInfo)
    }, [ id, navigate ])

    const { pathname, hash } = useLocation()

    type AnyRef = React.RefObject<HTMLDivElement | null>

    const scroll = (ref: AnyRef) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const handleLinkClick = (ref: AnyRef) => {
        scroll(ref as React.RefObject<HTMLDivElement>)
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        if (hash) {
            const targetElement = document.querySelector(hash)
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' })
            }
        }

        window.history.replaceState(null, '', pathname)
    }, [ pathname, hash ])

    return (
        <>
            <Navbar />
            <div className={classNames(s.inner, 'container')}>
                <Link to="/#news" className={s.innerBtn} onClick={() => handleLinkClick(NewsRef)}>
                    <SVGSelector type={SVG_SELECTOR_ICONS.chevronLeft} /> Come back
                </Link>
                <div className={s.container}>
                    {data?.map((item, index) => (
                        <div key={index} className={s.item}>
                            {item.title && <div className={s.itemTop}>{item.title}</div>}
                            {item.content && (
                                <div className={s.itemContent}>{item.content}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <News data={newsList?.filter(el => el.id !== id)} withoutRef />
            <Footer />
        </>
    )
}
