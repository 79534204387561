/* eslint-disable import/no-default-export */
/* eslint-disable import/no-cycle */
import React, { Ref, forwardRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'

import navigationConfig, { INavigationItemNoChildren, INavigationItemWithChildren } from '../../../../configs/navigationConfig'

import LinkWrapper from '../../../../components/linkWrapper'

import style from './Footer.module.sass'
import SVGSelector, { SVG_SELECTOR_ICONS } from '../../../../components/svgSelector'

export interface NavRef {
    current: HTMLDivElement | null;
}

interface NavigationItemLinkProps extends INavigationItemNoChildren {
    className?: any
}

const NavigationItemLink = ({
    title,
    to,
    className
}: NavigationItemLinkProps) => (
    <LinkWrapper link={to} className={classNames(style.itemLink, className)}>
        {title}
    </LinkWrapper>
)

interface NavigationItemProps extends INavigationItemWithChildren {
    onClick: (args: any) => void
}

const NavigationItem = forwardRef<HTMLDivElement, NavigationItemProps>(
    ({ title, to, children, onClick }: NavigationItemProps, ref: Ref<HTMLDivElement>) => {
        const [ expanded, setExpanded ] = useState(false)
        const hasChildren = !!children

        const hoverHandler = (state: boolean) => {
            setExpanded(state)
        }

        const clickHandler = () => {
            setExpanded(!expanded)
        }

        return (
            <div
                ref={ref}
                className={style.item}
                onMouseEnter={() => hoverHandler(true)}
                onMouseLeave={() => hoverHandler(false)}
                onClick={onClick}
            >
                <div className={style.itemWrapper} onClick={clickHandler}>
                    <NavigationItemLink
                        title={title}
                        to={to}
                        className={{ [style.itemLinkActive]: expanded }}
                    />
                    {hasChildren ? (
                        <SVGSelector
                            type={SVG_SELECTOR_ICONS.chevronBottom}
                            onClick={() => setExpanded(!expanded)}
                            style={{ transform: expanded ? 'rotate(180deg)' : undefined }}
                        />
                    ) : null}
                </div>
                {hasChildren && expanded && (
                    <div className={style.itemDD}>
                        {children.map(item => (
                            <div className={style.itemDDChild} key={item.title}>
                                <NavigationItemLink {...item} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
)

const Navigation: React.FC = () => {
    const { pathname, hash } = useLocation()

  type AnyRef = React.RefObject<HTMLDivElement | null>

  const scroll = (ref: AnyRef) => {
      if (ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' })
      }
  }

  const handleLinkClick = (ref: AnyRef) => {
      scroll(ref as React.RefObject<HTMLDivElement>)
  }

  useEffect(() => {
      //   window.scrollTo(0, 0)

      if (hash) {
          const targetElement = document.querySelector(hash)
          if (targetElement) {
              targetElement.scrollIntoView({ behavior: 'smooth' })
          }
      }

      window.history.replaceState(null, '', pathname)
  }, [ pathname, hash ])

  return (
      <nav className={style.navigation}>
          {navigationConfig.links.map(({ ref, ...item }) => (
              <NavigationItem
                  onClick={() => ref && handleLinkClick(ref)}
                  key={item.title}
                  {...item}
              />
          ))}
      </nav>
  )
}

export default Navigation
