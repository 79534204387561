/* eslint-disable import/no-default-export */
/* eslint-disable import/no-cycle */
import { FaqRef } from '../pages/Main/pageBlocks/Faq'
import { NewsRef } from '../pages/Main/pageBlocks/News'
import { ProductsRef } from '../pages/Main/pageBlocks/Products'
import { RoadmapRef } from '../pages/Main/pageBlocks/Roadmap'
import { PartnersRef } from '../pages/Main/pageBlocks/TrustedPartners'
import { AboutUsRef } from '../pages/Main/pageBlocks/Whitepaper'

export interface INavigationItemNoChildren {
    title: string
    to?: string
}

export interface INavigationItemWithChildren {
    title: string
    to?: string
    children?: INavigationItemNoChildren[]
    ref?: React.RefObject<HTMLDivElement | null>
}

interface INavigationConfig {
    links: Array<INavigationItemWithChildren>
}

const navigationConfig: INavigationConfig = {
    links: [
        { title: 'About us', to: '/#about-us', ref: AboutUsRef },
        { title: 'Products', to: '/#products', ref: ProductsRef },
        { title: 'Partners', to: '/#partners', ref: PartnersRef },
        { title: 'NEWS', to: '/#news', ref: NewsRef },
        { title: 'Roadmap', to: '/#roadmap', ref: RoadmapRef },
        { title: 'FAQ', to: '/#FAQ', ref: FaqRef },
        {
            title: 'Farm DFC',
            children: [
                { title: 'Arrakken game', to: 'https://t.me/ArrakkenPlanet_bot' },
                {
                    title: 'DFC lock',
                    to: 'https://tonraffles.app/lock/EQD26zcd6Cqpz7WyLKVH8x_cD6D7tBrom6hKcycv8L8hV0GP'
                },
                {
                    title: 'LP lock',
                    to: 'https://tonraffles.app/lock/EQCEho8oSvzVneM-q3ALV9GMOoRzlGNwrGtq4p2x3SnInMVA'
                }
            ]
        }
    ]
}
export default navigationConfig
