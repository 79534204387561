/* eslint-disable import/no-cycle */
import { FC } from 'react'
import { v1 } from 'uuid'

import classNames from 'classnames'

import { TrustedPartnerCard } from '../../../../components/trustedPartnerCard'
import { NavRef } from '../Footer/navigation'

import { trustedPartnersList } from '../../../../configs/trustedPartnersList'

import Decor from '../../../../assets/img/trusted-partners/decor.png'

import s from './TrustedPartners.module.sass'

export const PartnersRef: NavRef = { current: null }

interface TrustedPartnersProps {}

export const TrustedPartners: FC<TrustedPartnersProps> = () => (
    <section className={s.trustedPartners} id="partners" ref={PartnersRef}>
        <div className="container">
            <div className={s.trustedDecor}>
                <img src={Decor} alt="decor" />
            </div>
            <div className={s.decorI}></div>
            <p className={classNames(s.title, 'sectionTitle')}>
          Trusted by <br />
          These Folks
            </p>
            <div className={s.contentWrapper}>
                {trustedPartnersList.map(el => (
                    <TrustedPartnerCard key={v1()} {...el} />
                ))}
            </div>
        </div>
    </section>
)
