import { FC } from 'react'
import { Link } from 'react-router-dom'

import s from './TrustedPartnerCard.module.sass'

export interface ITrustedPartnerCard {
    logo: string
    title: string
    link: string
}

interface TrustedPartnerCardProps extends ITrustedPartnerCard {}

export const TrustedPartnerCard: FC<TrustedPartnerCardProps> = ({ logo, title, link }) => (
    <Link to={link} target='_blank' className={s.trustedCard}>
        <div className={s.trustedCardLogo}>
            <img src={logo} alt={title} />
        </div>
        <p className={s.trustedCardTitle}>{title}</p>
    </Link>
)
