/* eslint-disable max-len */
// eslint-disable-next-line import/no-cycle
import { IRoadmapItem } from '../pages/Main/pageBlocks/Roadmap'

export const roadmapList: IRoadmapItem[] = [
    {
        status: true,
        stage: 'INITIAL STAGE',
        title: 'DF Capital alliance founded',
        description: 'Several large telegram crypto-communities are uniting to promote the TON blockchain, creating DAO & DFC fun token as part of this project'
    },
    {
        status: false,
        stage: 'PREPARING',
        title: 'DAO airdrop & listings',
        description: 'DAO airdrop in several waves with 2 token pools -- for everyone and for holders only. 100k+ members in our TG channel, 1000+ DFC DAO holders, listings on all top TON CEX, CMC, Coingecko'
    },
    {
        status: false,
        stage: 'DEVELOPMENT AND INTEGRATIONS',
        title: 'DFC site created. Dev grants',
        description: 'New alliance partnerships, purchase and integration most useful TON services into our products, expansion, grants for TON developers'
    },
    {
        status: false,
        stage: 'UTILITY ADDED',
        title: 'The DFC token is being actively introduced into a wide range of our products and services',
        description: 'Analytical market tools for DFC holders, first special social privileges for holders, DAO trade signal drains, Release our first telegram mini-game on TON blockchain, CEX listings'
    },
    {
        status: false,
        stage: 'EXPANDING',
        title: 'New collaborations',
        description: 'DFC in market TOP 5 & added in the TON wallets. collaboration with all TOP TON projects, expanding influence in the TON community and receiving official grants from the TON blockchain'
    },
    {
        status: false,
        stage: '2ND STAGE',
        title: 'DFC token overclocking',
        description: 'Popularizing the TON blockchain out of the crypto, release a BIG TON GAME & DFCapital TON wallet, DFC reach $1 designated and goes to $10! to be continued ...'
    }
]
