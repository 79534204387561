/* eslint-disable import/no-default-export */
import { FC, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'
import style from './TokenomicsCards.module.sass'

export interface TokenomicsCardConfig {
    title: string
    svgDiagramTablet: ReactNode
    svgDiagramPhone: ReactNode
}

interface TokenomicsCardProps extends TokenomicsCardConfig {
    isActive: boolean
}

const TokenomicsCard: FC<TokenomicsCardProps> = ({ title, svgDiagramTablet, svgDiagramPhone }) => {
    const isMobile = useMediaQuery(({ query: '(max-width: 1000px)' }))
    return (
        <div className={style.card}>
            <p className={style.title}>{title}</p>
            <div className={style.diagram}>{isMobile ? svgDiagramPhone : svgDiagramTablet}</div>
        </div>
    )
}

export default TokenomicsCard
