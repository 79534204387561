/* eslint-disable max-len */
import dewallet from '../assets/img/products/logo/dewallet.png'
import dewalletImg from '../assets/img/products/images/dewallet.png'

import arraken from '../assets/img/products/logo/arraken.png'
import arrakenImg from '../assets/img/products/images/arrakenImg.png'

import devpn from '../assets/img/products/logo/devpn.png'
import devpnImg from '../assets/img/products/images/devpnImg.png'

import desim from '../assets/img/products/logo/desim.png'
import desimImg from '../assets/img/products/images/desimImg.png'

import decoupons from '../assets/img/products/logo/decoupons.png'
import decouponsImg from '../assets/img/products/images/decouponsImg.png'

import mnemonic from '../assets/img/products/logo/mnemonic.png'
import mnemonicImg from '../assets/img/products/images/mnemonicImg.png'

import dedex from '../assets/img/products/logo/dedex.png'
import dedexImg from '../assets/img/products/images/dedexImg.png'

import fund from '../assets/img/products/logo/fund.png'
import fundImg from '../assets/img/products/images/dfcImg.png'

import betty from '../assets/img/products/logo/betty.png'
import bettyImg from '../assets/img/products/images/bettyImg.png'

import coffee from '../assets/img/products/logo/coffee.png'
import coffeeImg from '../assets/img/products/images/coffee.png'

import arbuz from '../assets/img/products/logo/arbuz.png'
import arbuzImg from '../assets/img/products/images/arbuz.png'

import frog from '../assets/img/products/logo/frog.png'
import frogImg from '../assets/img/products/images/frog.png'

import { IExpandableCardItem } from '../components/expandableCardSlider/indext'

export const productsList: IExpandableCardItem[] = [
    {
        logo: dewallet,
        title: 'DeWallet',
        desc: 'We excel in tackling intricate challenges and are dedicated to delivering innovative solutions that propel the transformation of industries.',
        image: dewalletImg,
        link: 'https://t.me/dewallet'
    },
    {
        logo: arraken,
        title: 'Arrakken Planet',
        desc: 'One of the important tasks that the game solves is to get acquainted with the work in the TON blockchain through a telegram bot, learn how to use the DFC token and turn on its farm in an interesting way!',
        image: arrakenImg,
        link: 'https://t.me/ArrakkenPlanet_bot'
    },
    {
        logo: devpn,
        title: 'DeVPN',
        desc: 'Get a secure and fast connection with your TON wallet right inside Telegram.',
        image: devpnImg,
        link: 'https://t.me/delabvpnbot'
    },
    {
        logo: desim,
        title: 'DeSim',
        desc: 'Easily pay for your services with TON or tokens and stay connected at home or on the go.',
        image: desimImg,
        link: 'https://t.me/desimtonbot'
    },
    {
        logo: decoupons,
        title: 'DeCoupons',
        desc: 'DeCoupons revolutionizes the world of savings with its innovative smart contract-based coupon platform. With DeCoupons, you can trust in the security and transparency of your discount offers.',
        image: decouponsImg,
        link: 'https://t.me/delabtonbot/dewa'
    },
    {
        logo: mnemonic,
        title: 'Mnemonic Game',
        desc: 'One of the important tasks that the game solves is to get acquainted with the work in the TON blockchain through a telegram bot, learn how to use the DFC token and turn on its farm in an interesting way!',
        image: mnemonicImg,
        link: 'https://t.me/mnemonic_game_bot'
    },
    {
        logo: dedex,
        title: 'DeDEX',
        desc: 'One of the important tasks that the game solves is to get acquainted with the work in the TON blockchain through a telegram bot, learn how to use the DFC token and turn on its farm in an interesting way!',
        image: dedexImg,
        link: 'https://dex-aggregator.pages.dev/'
    },
    {
        logo: fund,
        title: 'DeFinder Capital Fund',
        desc: 'We invest in teams that build and support the decentralised web to contribute to a more inclusive, secure, and decentralized digital future.',
        image: fundImg,
        link: 'https://definder.fund/'
    },
    {
        logo: betty,
        title: 'betty.games',
        desc: 'A decentralized betting company that allows you to bet on the best events in sports and make money from it.',
        image: bettyImg,
        link: 'https://t.me/bettygames_bot/betty'
    },
    {
        logo: coffee,
        title: 'swap.coffee',
        desc: 'A decentralized betting company that allows you to bet on the best events in sports and make money from it.',
        image: coffeeImg,
        link: 'https://swap.coffee/'
    },
    {
        logo: arbuz,
        title: 'ClickARBUZ BOT',
        desc: 'An amazing clicker game with a prize pool of $1,000,000. Improve upgrades, invite friends, click on the watermelon.',
        image: arbuzImg,
        link: 'https://t.me/wmclick_bot'
    },
    {
        logo: frog,
        title: 'TON Frogs NFT',
        desc: 'TON Frogs is a limited NFT collection on TON (The Open Network) that consists of 5 555 unique 3D-animated frogs',
        image: frogImg,
        link: 'https://t.me/frogs'
    }
]
