/* eslint-disable max-len */
import { ICommunityCardItem } from '../components/communitySlider'

export const CommunityList: ICommunityCardItem[] = [
    {
        avatar: null,
        title: 'Professional trades & crypto enthusiasts',
        text: '“The community determines the price of the DFC token, which is freely traded on the market and may be of interest to traders and those who are interested in collecting crypto coins”'
    },
    {
        avatar: null,
        title: 'Developers and creatives',
        text: '“We encourage developers to implement DFC token into their products, issue grants and hold various competitions among TON programmers. Our social base allows to quickly and widely promote worthy products and services.'
    },
    {
        avatar: null,
        title: 'Professional trades & crypto enthusiasts',
        text: '“The community determines the price of the DFC token, which is freely traded on the market and may be of interest to traders and those who are interested in collecting crypto coins”'
    },
    {
        avatar: null,
        title: 'Administrators of crypto communities',
        text: 'DF Capital welcomes into alliance all communities that are committed to working together and constructively in the areas that we cover. Part of the DFC token supply in the form of a "DAO alliance pool" will be divided among new administrators of such communities with special vesting conditions.'
    },
    {
        avatar: null,
        title: 'Bloggers, content makers, active people looking for interesting prospects',
        text: 'New skills, acquaintances and hobbies through our system of donations and rewards on social networks will be of interest to bloggers, content makers and just people who like to learn new things and receive approval through the DFC token.'
    }
]
