/* eslint-disable import/no-default-export */
/* eslint-disable import/no-cycle */
import classNames from 'classnames'

// import Button from '../../../../components/button'
// import SVGSelector, {
//   SVG_SELECTOR_ICONS,
// } from '../../../../components/svgSelector'
import { NavRef } from '../Footer/navigation'

import DECOR from './img/decor.png'
import Sphere from '../../../../assets/img/sphere.png'

import style from './Whitepaper.module.sass'

export const AboutUsRef: NavRef = { current: null }

const CascadeItem = ({ title, text }: { title: string; text: string }) => (
    <div className={style.cascadeItem}>
        <div className={style.cascadeItemDot}>
            <div></div>
        </div>
        <p className={style.cascadeItemTitle}>{title}</p>
        <p className={style.cascadeItemText}>{text}</p>
    </div>
)

const WhitepaperBlock: React.FC = () => (
    <section className={style.whitepaperBlock} id="about-us" ref={AboutUsRef}>
        <div className="container">
            <div className={style.splineBg}>
                <img src={DECOR} alt="decor" />
            </div>

            <div className={style.textBlock}>
                <p className={classNames(style.title, 'sectionTitle')}>Whitepaper</p>
                <div className={style.textSection}>
                    <p className={style.text}>
              This is an integral utility social token with a wide open
              functionality. Technically it has mixed utility properties used in
              many Web3-services. Economically this is an asset freely wrapped
              in the crypto market. All actions of DF Capital are aimed at the
              development of social services and interaction between users of
              the TON blockchain and other blockchains of the cryptospace.
                    </p>
                    <p className={style.text}>
              Accordingly, the DFC token fulfills a connecting role and
              participates in all areas of work and areas of interest of DF
              Capital. We will strive for the widest possible introduction of
              the DFC token into our and third-party products, services and
              communities, developing its recognition and demand at the level of
              the most popular crypto assets.
                    </p>
                </div>
                {/* <Button
            icon={<SVGSelector type={SVG_SELECTOR_ICONS.document} />}
            className={style.button}
          >
            Read project manifesto
          </Button> */}
            </div>

            <div className={style.dfcBlock}>
                <div className={style.blob}>
                    <img className={style.sphere} src={Sphere} alt="sphere" />
                    <div className={style.blobBg}></div>
                </div>
                <div>
                    <p className={classNames(style.title, 'sectionTitle')}>
              DFC <span className="textMainGradient">Ecosystem</span>
                        <br />
              everywhere
                    </p>
                    <div className={style.cascade}>
                        <CascadeItem
                            title="EXCHANGE TOKEN"
                            text="Being a DAO token of the DF Capital group, the DFC allows you to easily and
                convenientlyexchange between members on TON blockchain, similar to how
                it is implemented in Dogecoin"
                        />
                        <CascadeItem
                            title="INTERGRAL ROLE"
                            text="Permeates and connects all products of the DF Capital ecosystem, is necessary for
              using services and brings benefits to holders. Providing DFC grants for developers.
              Encouraging social activity and donations."
                        />
                        <CascadeItem
                            title="EXTENSION TOOL"
                            text="Distributing among new holders across all telegram and social media resources, the
              DFC token attracts users to the DF Capital and TON community, contributing to the
              continuous growth of popularity."
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default WhitepaperBlock
